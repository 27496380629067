import { findAll } from '../../services/integratedProducts';
import { findOne } from '../../services/ProductService';

export const Types = {
  SET_PRODUCTS: '@editKit/setProducts',
  SET_PAGING: '@editKit/setPaging',
  SET_ORDERING: '@editKit/setOrdering',
  SET_FILTERS: '@editKit/setFilters',
  SET_LOADING: '@editKit/setLoading',
  SET_ERROR: '@editKit/setError',
  CLEAR: '@editKit/clear',

  SET_KIT_NAME: '@editKit/setKitName',

  ADD_KIT: '@editKit/kit/add',
  REMOVE_KIT: '@editKit/kit/remove',
  UPDATE_PRICE: '@editKit/price/update',
  UPDATE_AMOUNT: '@editKit/amount/update',
};

export const setKitName = (kitName) => ({
  type: Types.SET_KIT_NAME,
  payload: kitName,
});

export const setProducts = (products) => ({
  type: Types.SET_PRODUCTS,
  payload: products,
});

export const setPaging = (paging) => ({
  type: Types.SET_PAGING,
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: Types.SET_ORDERING,
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: Types.SET_FILTERS,
  payload: filters,
});

export const setLoading = (loading) => ({
  type: Types.SET_LOADING,
  payload: loading,
});

export const setError = (error) => ({
  type: Types.SET_ERROR,
  payload: error,
});

export const clear = () => ({
  type: Types.CLEAR,
});

export const addToKit = (product) => ({
  type: Types.ADD_KIT,
  payload: product,
});

export const removeFromKit = (product) => ({
  type: Types.REMOVE_KIT,
  payload: product,
});

export const updatePrice = (product, price) => ({
  type: Types.UPDATE_PRICE,
  payload: { product, price },
});

export const updateAmount = (product, amount) => ({
  type: Types.UPDATE_AMOUNT,
  payload: { product, amount },
});

export const fetchAll = (paging, ordering, filters) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await findAll(paging, ordering, filters);

    return dispatch([
      setLoading(false),
      setProducts(response.data),
      setPaging(response._metadata.paging),
      setOrdering(response._metadata.ordering),
      setFilters(response._metadata.filters),
      setError(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};

export const fetchProduct = (productId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const { data: productData } = await findOne(productId);

    productData?.componentes.forEach((kitItem) => {
      dispatch([
        setKitName(productData.pro_st_descricao),
        addToKit({
          ...productData,
          preco: kitItem.kit_de_precounitario,
          quantidade: kitItem.kit_in_quantidade,
          pro_st_descricao: kitItem.children.pro_st_descricao,
          pro_in_codigo: kitItem.child_pro_in_codigo,
        }),
      ]);
    });

    return null;
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
