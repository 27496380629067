import PropTypes from 'prop-types';
import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import { toast } from 'react-toastify';

// eslint-disable-next-line import/no-extraneous-dependencies
import { DeleteOutlineOutlined, NavigateNext } from '@material-ui/icons';
import { Avatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { MdAdd, MdRemove } from 'react-icons/md';
import { Table, Button, FloatingCard } from 'sonoma-design-system';

import {
  CenterColumn,
  Error,
  Section,
  ProductCard,
  ProductDescription,
  ProductLink,
  ProductCol,
} from '../../styles';
import {
  ButtonAdd,
  ButtonRemove,
  Helper,
  HelperLink,
  Description,
  Header,
  SelectedProductsContainer,
  Body,
  Input,
  PriceContainer,
  AmountContainer,
  Label,
} from './styles';

import {
  fetchAll as fetchAllFromNewKit,
  addToKit as addToNewKit,
  removeFromKit as removeFromNewKit,
  updatePrice as updatePriceNewKit,
  updateAmount as updateAmountNewKit,
} from '../../store/newKit';

import {
  fetchAll as fetchAllFromEditKit,
  addToKit as addToEditKit,
  removeFromKit as removeFromEditKit,
  updatePrice as updatePriceEditKit,
  updateAmount as updateAmountEditKit,
} from '../../store/editKit';

import defaultWineImg from '../../assets/default-wine.png';

function Status({ item }) {
  return (
    <CenterColumn>{item.pro_bo_ativo ? 'Ativo' : 'Invativo'}</CenterColumn>
  );
}

Status.propTypes = {
  item: PropTypes.object.isRequired,
};

function DateColumn({ item }) {
  return (
    <CenterColumn>
      {format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}
    </CenterColumn>
  );
}

DateColumn.propTypes = {
  item: PropTypes.object.isRequired,
};

function AddOrRemove({ item, isEdit }) {
  const dispatch = useDispatch();
  const kit = useSelector((state) =>
    isEdit ? state.product.edit.kit.selected : state.product.new.kit.selected
  );

  const isProductOnKit = useMemo(
    () => kit.find((prod) => prod.pro_in_codigo === item.pro_in_codigo),
    [kit, item]
  );

  return (
    <CenterColumn>
      {isProductOnKit ? (
        <ButtonRemove
          onClick={() =>
            dispatch(isEdit ? removeFromEditKit(item) : removeFromNewKit(item))
          }
        >
          <MdRemove strokeWidth={1} />
        </ButtonRemove>
      ) : (
        <ButtonAdd
          onClick={() =>
            dispatch(isEdit ? addToEditKit(item) : addToNewKit(item))
          }
        >
          <MdAdd strokeWidth={1} />
        </ButtonAdd>
      )}
    </CenterColumn>
  );
}

AddOrRemove.propTypes = {
  item: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

function Product({ item }) {
  return (
    <ProductCol>
      <ProductCard>
        <Avatar
          style={{ marginRight: '10px' }}
          src={item.image_firebase_url || defaultWineImg}
          alt={item.pro_st_descricao}
        />

        <div>
          <ProductDescription title={item.pro_st_descricao}>
            {item.pro_st_descricao}
          </ProductDescription>
          <ProductLink
            href='https://www.sonoma.com.br/'
            target='_blank'
            rel='noreferrer'
          >
            Ver no site
          </ProductLink>
        </div>
      </ProductCard>
    </ProductCol>
  );
}

Product.propTypes = {
  item: PropTypes.object.isRequired,
};

function ButtonContinue({ onClick, fullWidth }) {
  return (
    <Button size='sm' onClick={onClick} fullWidth={fullWidth}>
      SALVAR E AVANÇAR <NavigateNext fontSize='3rem' />
    </Button>
  );
}

ButtonContinue.propTypes = {
  onClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

ButtonContinue.defaultProps = {
  fullWidth: false,
};

export default function ProductsList({ onNext, isEdit }) {
  const dispatch = useDispatch();

  const { products, loading, error, paging, ordering, filters, selected } =
    useSelector((state) =>
      isEdit ? state.product.edit.kit : state.product.new.kit
    );

  const kitItemsAmount = useMemo(() => selected?.length, [selected]);

  useEffect(() => {
    dispatch(isEdit ? fetchAllFromEditKit() : fetchAllFromNewKit());
  }, [dispatch, isEdit]);

  const handleNextStep = useCallback(() => {
    if (onNext === null) {
      toast.warning('Por favor, preencha os campos obrigatórios');
      return;
    }

    if (selected.length === 0) {
      toast.warning('Nenhum produto selecionado');
      return;
    }

    const hasEmptyProduct = selected.find(
      (prod) => !prod.quantidade || parseInt(prod.quantidade, 10) === 0
    );
    const hasFreeProduct = selected.find(
      (prod) => !prod.preco || parseFloat(prod.preco) === 0
    );

    if (hasEmptyProduct || hasFreeProduct) {
      toast.warning('Por favor, preencha a quantidade e o preço dos produtos');
      return;
    }

    onNext();
  }, [selected, onNext]);

  const cols = useMemo(
    () => [
      { id: 'pro_st_descricao', title: 'Produto', component: Product },
      { id: 'pro_st_sku', title: 'SKU' },
      { id: 'updatedAt', title: 'Data de indexação', component: DateColumn },
      {
        id: 'pro_bo_ativo',
        title: 'Status',
        component: Status,
      },
      {
        id: 'action',
        title: 'Adicionar',
        component: (props) => <AddOrRemove isEdit={isEdit} {...props} />,
      },
    ],
    [isEdit]
  );

  const changePrice = useCallback(
    (product, price) =>
      dispatch(
        isEdit
          ? updatePriceEditKit(product, price)
          : updatePriceNewKit(product, price)
      ),
    [dispatch, isEdit]
  );

  const changeAmount = useCallback(
    (product, price) =>
      dispatch(
        isEdit
          ? updateAmountEditKit(product, price)
          : updateAmountNewKit(product, price)
      ),
    [dispatch, isEdit]
  );

  return (
    <Section>
      {loading && <Skeleton animation='wave' height={300} />}

      {error && <Error>Tivemos um problema ao buscar os produtos.</Error>}

      {!loading && !error && (
        <Table
          refValue={filters.ref}
          rows={products}
          columns={cols}
          ordering={ordering}
          onChangeOrder={(order) =>
            dispatch(
              isEdit
                ? fetchAllFromEditKit(paging, order, filters)
                : fetchAllFromNewKit(paging, order, filters)
            )
          }
          paging={paging}
          onChangePage={(page) =>
            dispatch(
              isEdit
                ? fetchAllFromEditKit(
                    { page, limit: paging.limit },
                    ordering,
                    filters
                  )
                : fetchAllFromNewKit(
                    { page, limit: paging.limit },
                    ordering,
                    filters
                  )
            )
          }
          onSearch={(ref) =>
            dispatch(
              isEdit
                ? fetchAllFromEditKit(
                    { page: 1, limit: paging.limit },
                    { order_by: 'pro_st_descricao', order: 'asc' },
                    { ref }
                  )
                : fetchAllFromNewKit(
                    { page: 1, limit: paging.limit },
                    { order_by: 'pro_st_descricao', order: 'asc' },
                    { ref }
                  )
            )
          }
          searchPlaceholder='Buscar por Nome ou SKU'
          headerComponent={() => <ButtonContinue onClick={handleNextStep} />}
        />
      )}

      {/* NOTE: don't know why only strings are working in notification prop */}
      <FloatingCard
        title='Produtos Adicionados'
        notification={`${kitItemsAmount}`}
      >
        <Helper>
          Dúvidas? Siga as <HelperLink to='/kits/create'>instruções</HelperLink>{' '}
          para saber como prosseguir.
        </Helper>

        {selected.map((product) => (
          <SelectedProductsContainer key={product.pro_in_codigo}>
            <Header>
              <Avatar
                style={{ marginRight: '10px' }}
                src={product.image_firebase_url || defaultWineImg}
                alt={product.pro_st_descricao}
              />

              <Description>{product.pro_st_descricao}</Description>

              <DeleteOutlineOutlined
                onClick={() =>
                  dispatch(
                    isEdit
                      ? removeFromEditKit(product)
                      : removeFromNewKit(product)
                  )
                }
                cursor='pointer'
                htmlColor='#A8727B'
              />
            </Header>

            <Body>
              <PriceContainer>
                <Label htmlFor='preco_unitario'>Preço Unitário</Label>
                <Input
                  value={product.preco}
                  onChange={(e) => changePrice(product, e.target.value)}
                  width={155}
                  id='preco_unitario'
                  type='number'
                />
              </PriceContainer>

              <AmountContainer>
                <Label htmlFor='quantidade'>Qtde</Label>
                <Input
                  value={product.quantidade}
                  onChange={(e) => changeAmount(product, e.target.value)}
                  width={45}
                  id='quantidade'
                  type='number'
                />
              </AmountContainer>
            </Body>
          </SelectedProductsContainer>
        ))}

        <ButtonContinue fullWidth onClick={handleNextStep} />
      </FloatingCard>
    </Section>
  );
}

ProductsList.propTypes = {
  onNext: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

ProductsList.defaultProps = {
  isEdit: false,
};
