import PropTypes from 'prop-types';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { format } from 'date-fns';

import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import { MdAddCircle, MdCheck, MdClose } from 'react-icons/md';
import { FaDownload } from 'react-icons/fa';
import { Table, DropdownButton, Button } from 'sonoma-design-system';

import CheckboxColumn from './CheckboxColumn';
import ProductColumn from './ProductColumn';
import ActionsColumn from './ActionsColumn';
// import VtexIntegrationStatusColumn from './VtexIntegrationStatusColumn';
import SkuColumn from './SkuColumn';

import { ButtonContent, Container } from './styles';
import { CenterColumn, Error, Section } from '../../styles';
import spreadsheetModel from '../../assets/modelo_cadastro_de_produtos.xlsx';

import {
  fetchAll,
  clear,
  fetchProducts as fetchProductsToCsv,
} from '../../store/productsList';
import { getUserRoles, getUser } from '../../auth/auth';

import { downloadCsv } from '../../utils/csvDownload';
import { UploadFilesModal } from '../../components/UploadFilesModal';
import { createByCsv } from '../../services/ProdutoCsvService';

function StatusIndicator({ item }) {
  return (
    <CenterColumn>
      {item.pro_bo_ativo ? (
        <MdCheck color='#85B074' size='20' />
      ) : (
        <MdClose color='#FF0000' size='20' />
      )}
    </CenterColumn>
  );
}

StatusIndicator.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.shape({
    component: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

function DateColumn({ item }) {
  return (
    <CenterColumn>
      {format(new Date(item.updatedAt), 'dd/MM/yyyy HH:mm')}
    </CenterColumn>
  );
}

DateColumn.propTypes = {
  item: PropTypes.object.isRequired,
};
function Wrapper({ productsAmount }) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const user = getUser();
  const roles = getUserRoles();
  const isAdmin = useMemo(() => roles.find((r) => r === 'ADMIN'), [roles]);

  const ordering = useSelector((state) => state.product.list.ordering);
  const filters = useSelector((state) => state.product.list.filters);
  const selectedFilial = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );
  const [loadingCsv, setLoadingCsv] = useState(false);

  const handleOpenedModal = useCallback(() => {
    setIsModalOpened(!isModalOpened);
  }, [isModalOpened]);

  const handleCsvDownload = async () => {
    setLoadingCsv(true);
    const pagingData = { page: 1, limit: 'all' };

    await fetchProductsToCsv(
      selectedFilial?.fil_in_codigo || null,
      pagingData,
      ordering,
      filters
    )
      .then((req) => {
        const dataFormatted = req.data.map((e) => ({
          Sku: e.pro_st_sku || '-',
          Nome: e.pro_st_descricao || '-',
          Pais: e.pais?.pai_st_name || '-',
          Regiao: e.regiao?.reg_st_name || '-',
          Conteudo: e.pro_st_conteudo || '-',
          Curador:
            e.vinho?.curador?.cur_st_nome ||
            e.gourmet?.curador?.cur_st_nome ||
            '-',
          URLAmigavel: e.pro_st_url || '-',
          Tags: e.pro_st_tags || '-',
          CategoriaPrincipal: e.departamento?.dep_st_descricao || '-',
          EAN: e.pro_st_ean || '-',
          Descricao: e.pro_st_descricao || '-',
          VendidoPor: e.pro_st_vendidopor || '-',
          EntreguePor: e.pro_st_entreguepor || '-',
          Sobre: e.pro_lt_sobre || '-',
          Historia: e.pro_lt_historia || '-',
          Score: e.pro_in_score || '-',
          ExibirNoSite: e.pro_bo_exibirnosite || 'False',
          Ativo: e.pro_bo_ativo || 'False',
          ExibirAviseMeEsgotado: e.pro_bo_mostraresgotado || 'False',
          PrecoDe: parseFloat(e.pro_de_precode).toFixed(2) || '-',
          PrecoPor: parseFloat(e.pro_de_precopor).toFixed(2) || '-',
          PrecoCaixa: parseFloat(e.pro_de_precoatacado).toFixed(2) || '-',
          DataDeLancamento:
            format(new Date(e.createdAt), 'dd/MM/yyyy HH:mm') || '-',
          TeorAlcoolico: e.vinho?.vin_de_teoralcoolico || '-',
          Temperatura: e.vinho?.vin_de_temperatura || '-',
          TempoDeDescanso: e.vinho?.vin_in_tempodescanso || '-',
          TempoDeBarrica: e.vinho?.vin_in_tempobarrica || '-',
          VinhosNaCaixa: e.vinho?.vin_in_quantidadecaixa || '-',
          Safra: e.vinho?.vin_in_safra || '-',
          Produtor: e.queijo?.que_st_produtor || '-',
          Casca: e.queijo?.que_st_casca || '-',
          Massa: e.queijo?.que_st_massa || '-',
          Intensidade: e.queijo?.que_st_intensidade || '-',
          VariedadeLeite: e.queijo?.que_st_variedadeleite || '-',
          Vegano: e.queijo?.que_bo_vegano || 'False',
          Vegetariano: e.queijo?.que_bo_vegetariano || 'False',
          QueijoIngredientes: e.queijo?.que_lt_ingredientes || '-',
          QueijoInformacoesNutricionais:
            e.queijo?.que_lt_informacoesnutri || '-',
          QueijoAlergicos: e.queijo?.que_lt_alergicos || '-',
          QueijoArmazenamento: e.queijo?.que_lt_armazenamento || '-',
          GourmetIngredientes: e.gourmet?.gou_lt_ingredientes || '-',
          GourmetInformacoesNutricionais:
            e.gourmet?.gou_lt_informacoesnutri || '-',
          GourmetAlergicos: e.gourmet?.gou_lt_alergicos || '-',
          GourmetArmazenamento: e.gourmet?.gou_lt_armazenamento || '-',
        }));
        downloadCsv('Sonoma-Market-Produtos-Relatorio.csv', dataFormatted);
        setLoadingCsv(false);
      })
      .catch(() => {
        toast.error('Ocorreu um erro, por favor tente novamente mais tarde.');
        setLoadingCsv(false);
      });
  };

  const KBYTE = 1000; // bytes
  const MEGABYTE = 1000 * KBYTE;

  const MAX_SIZE_IN_BYTES = 10 * MEGABYTE;

  return (
    <Container>
      {isAdmin && user?.slr_in_codigo && (
        <div>
          <UploadFilesModal
            open={isModalOpened}
            setOpen={handleOpenedModal}
            maxSizeInBytes={MAX_SIZE_IN_BYTES}
            allowedFileExtensions={['.csv']}
            onSubmit={createByCsv}
            label='Importar produtos'
            modelFile={spreadsheetModel}
            modelFileName='modelo_cadastro_de_produtos.xlsx'
          />
        </div>
      )}
      {productsAmount > 0 && (
        <div style={{ margin: '15px 10px' }}>
          <Button
            disabled={loadingCsv}
            onClick={handleCsvDownload}
            size='medium'
          >
            {loadingCsv ? (
              <CircularProgress color='#9cd186' size='25px' />
            ) : (
              <>
                Baixar Dados &nbsp; <FaDownload />
              </>
            )}
          </Button>
        </div>
      )}
      <DropdownButton
        size='sm'
        as={Link}
        options={[
          { description: 'Vinhos', link: '/produtos/vinho/create' },
          { description: 'Queijos', link: '/produtos/queijo/create' },
          { description: 'Outros Produtos', link: '/produtos/gourmet/create' },
          { description: 'Kits', link: '/produtos/kit/create' },
        ].filter((e) => e)}
      >
        <ButtonContent>
          Adicionar <MdAddCircle size={21} />
        </ButtonContent>
      </DropdownButton>
    </Container>
  );
}

Wrapper.propTypes = {
  productsAmount: PropTypes.number.isRequired,
};

function SellerDescription({ item }) {
  return (
    <CenterColumn title={item.filial?.fil_st_nome || '-'}>
      {item.filial?.fil_st_nome.substring(0, 13).concat('...') || '-'}
    </CenterColumn>
  );
}

SellerDescription.propTypes = {
  item: PropTypes.object.isRequired,
};

function UpdatedBy({ item }) {
  return <CenterColumn>{item.usuario?.usu_st_nome || '-'}</CenterColumn>;
}

UpdatedBy.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function ProductsList() {
  const dispatch = useDispatch();
  const history = useHistory();

  const products = useSelector((state) => state.product.list.products);
  const loading = useSelector((state) => state.product.list.loading);
  const error = useSelector((state) => state.product.list.error);

  const paging = useSelector((state) => state.product.list.paging);
  const ordering = useSelector((state) => state.product.list.ordering);
  const filters = useSelector((state) => state.product.list.filters);

  const selectedFilial = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );

  const isAdmin = useMemo(() => getUserRoles().find((r) => r === 'ADMIN'), []);
  const isReviewer = useMemo(
    () => getUserRoles().find((r) => r === 'INSIDER'),
    []
  );

  const handleRedirectToEditProduct = useCallback(
    (item) => {
      if (item.vinho)
        history.push(`/produtos/${item.pro_in_codigo}/vinho/edit`);

      if (item.queijo)
        history.push(`/produtos/${item.pro_in_codigo}/queijo/edit`);

      if (item.gourmet)
        history.push(`/produtos/${item.pro_in_codigo}/gourmet/edit`);

      if (item.pro_bo_kit)
        history.push(`/produtos/${item.pro_in_codigo}/kit/edit`);
    },
    [history]
  );

  const handleRedirectToPromoPage = useCallback(
    (item) => {
      history.push(`/produtos/${item.pro_in_codigo}/promo`);
    },
    [history]
  );

  useEffect(() => {
    if (selectedFilial) {
      dispatch(
        fetchAll(
          {
            page: 1,
            limit: 15,
          },
          {
            order: 'desc',
            order_by: 'updatedAt',
            avaliableOrderBy: [],
          },
          {
            ref: '',
            reviewed: true,
            delta: 0,
          },
          selectedFilial?.fil_in_codigo
        )
      );
    } else {
      dispatch(fetchAll());
    }

    return () => dispatch(clear());
  }, [dispatch, isAdmin, isReviewer, selectedFilial]);

  const cols = useMemo(
    () => [
      {
        id: 'pro_st_titulopage',
        title: 'Produto',
        component: (props) => (
          <ProductColumn
            {...props}
            handleRedirectToEditProduct={handleRedirectToEditProduct}
          />
        ),
      },
      { id: 'pro_st_sku', title: 'SKU', component: SkuColumn },
      (isAdmin || isReviewer) && {
        id: 'seller',
        title: 'Filial',
        component: SellerDescription,
      },
      { id: 'updatedAt', title: 'Última alteração', component: DateColumn },
      { id: 'updatedBy', title: 'Alterado por', component: UpdatedBy },
      { id: 'active', title: 'Produto ativo', component: StatusIndicator },
      {
        id: 'pro_bo_ativo',
        title: 'Produto Visível',
        component: CheckboxColumn,
      },
      // {
      //   id: 'integrationStatus',
      //   title: 'Status de integração',
      //   component: VtexIntegrationStatusColumn,
      // },
      {
        id: 'action',
        title: '',
        component: (props) => (
          <ActionsColumn
            {...props}
            handleRedirectToEditProduct={handleRedirectToEditProduct}
            handleRedirectToPromoPage={handleRedirectToPromoPage}
          />
        ),
      },
    ],
    [
      isAdmin,
      isReviewer,
      handleRedirectToEditProduct,
      handleRedirectToPromoPage,
    ]
  );

  return (
    <Section style={{ marginTop: '130px' }}>
      {loading && <Skeleton animation='wave' height={300} />}

      {error && <Error>Tivemos um problema ao buscar os produtos.</Error>}

      {!loading && !error && (
        <Table
          headerTitle='Lista de Produtos e SKUs'
          refValue={filters.ref}
          rows={products}
          columns={cols.filter((c) => c)}
          ordering={ordering}
          onChangeOrder={(order) =>
            dispatch(
              fetchAll(paging, order, filters, selectedFilial?.fil_in_codigo)
            )
          }
          paging={paging}
          onChangePage={(page) =>
            dispatch(
              fetchAll(
                { page, limit: paging.limit },
                ordering,
                filters,
                selectedFilial?.fil_in_codigo
              )
            )
          }
          onSearch={(ref) =>
            dispatch(
              fetchAll(
                { page: 1, limit: paging.limit },
                { order_by: 'pro_st_titulopage', order: 'asc' },
                { ref },
                selectedFilial?.fil_in_codigo
              )
            )
          }
          searchPlaceholder='Buscar por Nome ou SKU'
          headerComponent={() => <Wrapper productsAmount={products.length} />}
        />
      )}
    </Section>
  );
}
