import PropTypes from 'prop-types';
import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Options } from 'sonoma-design-system';
import { MdDelete, MdEdit, MdLocalOffer } from 'react-icons/md';

import Swal from 'sweetalert2';
import { getUserRoles } from '../../../auth/auth';
import { deleteProduct } from '../../../services/ProductService';
import { fetchAll } from '../../../store/productsList';
import { insertIf } from '../../../utils/arrays';

export default function ActionsColumn({
  item,
  handleRedirectToEditProduct,
  handleRedirectToPromoPage,
}) {
  const dispatch = useDispatch();

  const paging = useSelector((state) => state.product.list.paging);
  const ordering = useSelector((state) => state.product.list.ordering);
  const filters = useSelector((state) => state.product.list.filters);

  const roles = getUserRoles();
  const isAdmin = useMemo(() => roles.find((r) => r === 'ADMIN'), [roles]);
  const isInsider = useMemo(() => roles.find((r) => r === 'INSIDER'), [roles]);

  const handleDeleteProduto = useCallback(async () => {
    const result = await Swal.fire({
      title: `Tem certeza que deseja excluir '${item.pro_st_descricao}'?`,
      text: `Ao excluir o produto ${item.pro_st_descricao}, você não terá mais acesso a esse produto no backoffice.`,
      icon: 'warning',
      iconColor: 'rgba(156,209,134,1)',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Sim, excluir!',
      confirmButtonColor: 'rgba(156,209,134,1)',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      await deleteProduct(item.pro_in_codigo);
      dispatch(
        fetchAll({ page: paging.page, limit: paging.limit }, ordering, filters)
      );
    }
  }, [
    dispatch,
    filters,
    item.pro_in_codigo,
    item.pro_st_descricao,
    ordering,
    paging.limit,
    paging.page,
  ]);

  return (
    <td>
      <Options
        options={[
          ...insertIf(
            item.vinho || item.queijo || item.gourmet || item.pro_bo_kit,
            [
              {
                icon: MdEdit,
                text: 'Editar',
                onClick: () => handleRedirectToEditProduct(item),
              },
            ]
          ),
          ...insertIf(isInsider || isAdmin, [
            {
              icon: MdDelete,
              text: 'Excluir',
              onClick: handleDeleteProduto,
            },
          ]),
          ...insertIf(isInsider || isAdmin, [
            {
              icon: MdLocalOffer,
              text: 'Adicionar preço promocional',
              onClick: () => handleRedirectToPromoPage(item),
            },
          ]),
        ]}
      />
    </td>
  );
}

ActionsColumn.propTypes = {
  item: PropTypes.object.isRequired,
  handleRedirectToEditProduct: PropTypes.func.isRequired,
  handleRedirectToPromoPage: PropTypes.func.isRequired,
};
