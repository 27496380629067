import { Types } from './newKit.action';

const INITIAL_STATE = {
  products: [],
  selected: [],
  kitName: '',
  loading: false,
  error: false,
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order_by: null,
    order: 'asc',
    availableOrderby: [],
  },
  filters: {
    ref: '',
  },
};

const setKitName = (state, action) => ({
  ...state,
  kitName: action.payload,
});

const setProducts = (state, action) => ({
  ...state,
  products: action.payload,
});

const setPaging = (state, action) => ({
  ...state,
  paging: action.payload,
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: action.payload,
});

const setFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

const addToKit = (state, action) => ({
  ...state,
  selected: [...state.selected, { ...action.payload, preco: 0, quantidade: 0 }],
});

const removeFromKit = (state, action) => ({
  ...state,
  selected: state.selected.filter(
    (prod) => prod.pro_in_codigo !== action.payload.pro_in_codigo
  ),
});

const updatePrice = (state, action) => ({
  ...state,
  selected: state.selected.map((prod) => {
    if (prod.pro_in_codigo === action.payload.product.pro_in_codigo) {
      return {
        ...prod,
        preco: action.payload.price,
      };
    }

    return prod;
  }),
});

const updateAmount = (state, action) => ({
  ...state,
  selected: state.selected.map((prod) => {
    if (prod.pro_in_codigo === action.payload.product.pro_in_codigo) {
      return {
        ...prod,
        quantidade: action.payload.amount,
      };
    }

    return prod;
  }),
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_KIT_NAME:
      return setKitName(state, action);
    case Types.SET_PRODUCTS:
      return setProducts(state, action);
    case Types.SET_PAGING:
      return setPaging(state, action);
    case Types.SET_ORDERING:
      return setOrdering(state, action);
    case Types.SET_FILTERS:
      return setFilters(state, action);
    case Types.SET_LOADING:
      return setLoading(state, action);
    case Types.SET_ERROR:
      return setError(state, action);
    case Types.ADD_KIT:
      return addToKit(state, action);
    case Types.REMOVE_KIT:
      return removeFromKit(state, action);
    case Types.UPDATE_PRICE:
      return updatePrice(state, action);
    case Types.UPDATE_AMOUNT:
      return updateAmount(state, action);
    case Types.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
