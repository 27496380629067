import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';

import {
  ProductCard,
  ProductCol,
  ProductDescription,
  ProductLink,
} from '../../../styles';

import defaultWineImg from '../../../assets/default-wine.png';

export default function ProductColumn({
  item,
  handleRedirectToEditProduct,
  readonly,
}) {
  const description = item.pro_st_titulopage || item.pro_st_descricao;

  return (
    <ProductCol>
      <ProductCard>
        <Avatar
          variant='square'
          style={{
            marginRight: '10px',
            borderRadius: '5px',
            border: '1px solid #E0E0E0',
            height: '48px',
            width: '48px',
            cursor: 'pointer',
          }}
          src={item.image_firebase_url || defaultWineImg}
          alt={description}
          onClick={!readonly && (() => handleRedirectToEditProduct(item))}
        />

        <div>
          <ProductDescription
            title={description}
            onClick={!readonly && (() => handleRedirectToEditProduct(item))}
          >
            {description}
          </ProductDescription>
          <ProductLink
            href={`https://www.sonoma.com.br/product/${item?.pro_st_url}`}
            target='_blank'
            rel='noreferrer'
          >
            Ver no site
          </ProductLink>
        </div>
      </ProductCard>
    </ProductCol>
  );
}

ProductColumn.propTypes = {
  item: PropTypes.object.isRequired,
  handleRedirectToEditProduct: PropTypes.func,
  readonly: PropTypes.bool,
};

ProductColumn.defaultProps = {
  handleRedirectToEditProduct: null,
  readonly: false,
};
