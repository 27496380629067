import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { BreadcrumbStepper, useStepper, Input } from 'sonoma-design-system';
import { Title } from '../../layout/Title';

import ProductsList from '../../components/ProductsList';
import InformacoesForm from '../../components/InformacoesForm';
import FreteForm from '../../components/FreteForm';
import ImagensForm from '../../components/ImagensForm';

import { Main } from './styles';

import store from '../../store';
import {
  clear as clearNewKitData,
  setKitName as setNewKitName,
} from '../../store/newKit';
import { clearAll as clearInfo } from '../../store/informacoesForm';
import { clearAll as clearFreight } from '../../store/freteForm';
import {
  clearAll as clearImagens,
  fetchProduct as fetchImagensForm,
} from '../../store/imagensForm';

import {
  fetchProduct as fetchEditProduct,
  setKitName as setEditKitName,
  clear as clearEditKitData,
} from '../../store/editKit';
import {
  fetchProduct as fetchInfoEdit,
  clearAll as clearInfoEdit,
} from '../../store/informacoesEdit';
import {
  fetchProduct as fetchFreteEdit,
  clearAll as clearFreightEdit,
} from '../../store/freteEdit';

import { createKit } from '../../services/ProductService';

import { clearBlankKeysFromNested } from '../../utils/objects';
import { getUser, getUserRoles } from '../../auth/auth';
import { clearDB } from '../../config/db';

export default function CreateKit() {
  const { selected, next, previous } = useStepper();
  const [isLoading, setLoading] = useState(false);

  const { pro_in_codigo } = useParams();
  const isEdit = Boolean(pro_in_codigo);

  const kitName = useSelector((state) =>
    isEdit ? state.product.edit.kit.kitName : state.product.new.kit.kitName
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const isDeliveryBySeller =
    getUserRoles().includes('DELIVERY_BY_SELLER') || getUser().slr_in_codigo;

  const titles = useMemo(
    () => [
      'COMPONENTES',
      'INFORMAÇÕES',
      isDeliveryBySeller ? 'FRETE E VENDA' : 'VENDA',
      'IMAGENS E VÍDEOS',
    ],
    [isDeliveryBySeller]
  );

  useEffect(() => {
    if (pro_in_codigo) {
      dispatch([
        fetchEditProduct(pro_in_codigo),
        fetchInfoEdit(pro_in_codigo),
        fetchFreteEdit(pro_in_codigo),
        fetchImagensForm(pro_in_codigo),
      ]);
    }
  }, [dispatch, isEdit, pro_in_codigo]);

  useEffect(
    () => () => {
      dispatch(
        isEdit
          ? [clearEditKitData(), clearInfoEdit(), clearFreightEdit()]
          : [clearNewKitData(), clearInfo(), clearFreight(), clearImagens()]
      );
      clearDB();
    },
    [dispatch, isEdit]
  );

  const getMainImagePath = useCallback(
    (images) => images.find((img) => img.main)?.path,
    []
  );

  const getPreviouslySavedURLs = useCallback(
    (images) =>
      images.filter((i) => i.pim_st_imagesrc).map((i) => i.pim_st_imagesrc),
    []
  );

  const getNewImageFiles = useCallback(
    (images) => images.filter((i) => !i.pim_st_imagesrc),
    []
  );

  const handleCreateProduct = useCallback(
    async (images) => {
      setLoading(true);

      try {
        const state = isEdit
          ? store.getState().product.edit
          : store.getState().product.new;
        const user = getUser();

        const kitPrice = state?.kit?.selected?.reduce(
          (acc, item) => acc + Number(item.preco) * Number(item.quantidade),
          0
        );

        const product = clearBlankKeysFromNested(state);

        const {
          kit: { selected: rawComponents },
          informacoes: { form: infoData },
          frete: { form: freightData },
          imagens: { form: imagens },
        } = product;

        const {
          produtos_suplentes,
          categorias_similares,
          pro_lt_sobre,
          pro_lt_historia,
          ...dataInfo
        } = infoData;

        const similares = Object.keys(produtos_suplentes).map((key) => ({
          pro_in_codigo: produtos_suplentes[key].child_pro_in_codigo,
          pro_st_sku: produtos_suplentes[key].children.pro_st_sku,
          pro_st_descricao: produtos_suplentes[key].children.pro_st_descricao,
        }));

        const componentes = Object.keys(rawComponents).map((key) => ({
          pro_in_codigo: rawComponents[key].pro_in_codigo,
          pro_st_sku: rawComponents[key].pro_st_sku,
          pro_st_descricao: rawComponents[key].pro_st_descricao,
          preco: rawComponents[key].preco,
          quantidade: rawComponents[key].quantidade,
        }));

        // main image path
        const main = getMainImagePath(images);

        // previous saved firebase files
        const previousURLs = getPreviouslySavedURLs(images);

        // new image files
        const newFiles = getNewImageFiles(images);

        const dataToCreate = {
          ...freightData,
          ...dataInfo,

          ...(pro_in_codigo && { pro_in_codigo: pro_in_codigo }),
          pro_st_descricao: kitName,
          pro_lt_sobre: pro_lt_sobre?.replaceAll('<p></p>', '</br>'),
          pro_lt_historia: pro_lt_historia?.replaceAll('<p></p>', '</br>'),
          pro_de_precopor: kitPrice,

          pro_st_titulopage: kitName,
          pro_st_nomesite: kitName,
          pro_st_palavraschave: infoData.pro_st_tags,
          pro_st_video: imagens?.pro_st_video,

          updatedBy: user.usu_in_codigo,

          similares,
          categorias_similares,
          componentes,

          main,
          previousURLs,
        };

        const dataToCreateWithouBlank = clearBlankKeysFromNested(dataToCreate);

        await createKit(dataToCreateWithouBlank, newFiles);

        history.push('/produtos');
      } catch (err) {
        // handle error
        setLoading(false);
      }
    },
    [
      getMainImagePath,
      getNewImageFiles,
      getPreviouslySavedURLs,
      history,
      isEdit,
      kitName,
      pro_in_codigo,
    ]
  );

  const FirstStep = () => {
    const [name, setName] = useState('');

    useEffect(() => {
      if (isEdit) {
        setName(kitName);
      }
    }, []);

    const handleChange = useCallback((e) => {
      setName(e.target.value);
    }, []);

    const handleOnNext = useCallback(() => {
      dispatch(isEdit ? setEditKitName(name) : setNewKitName(name));
      next();
    }, [name]);

    return (
      <>
        <Grid item xs={12} lg={6} style={{ margin: '20px 0' }}>
          <Input
            inputId='pro_st_descricao'
            error={!name}
            errorText={!name && 'Preencha o nome do kit'}
            value={name}
            handleChange={handleChange}
            size='small'
            label='Nome do kit'
            fullWidth
            required
          />
        </Grid>
        <ProductsList onNext={!name ? null : handleOnNext} isEdit={isEdit} />
      </>
    );
  };

  return (
    <Main>
      <Title>Produtos e SKUs | {titles[selected]}</Title>

      <BreadcrumbStepper
        selected={selected}
        steps={[
          {
            title: 'Lista de produtos',
            component: () => <FirstStep />,
          },
          {
            title: 'Informações',
            component: () => (
              <InformacoesForm
                onNext={next}
                onPrevious={previous}
                isKit
                isEdit={isEdit}
              />
            ),
          },
          {
            title: isDeliveryBySeller ? 'Frete e Venda' : 'Venda',
            component: () => (
              <FreteForm
                onNext={next}
                onPrevious={previous}
                isKit
                isEdit={isEdit}
              />
            ),
          },
          {
            title: 'Imagens e Vídeos',
            component: () => (
              <ImagensForm
                loading={isLoading}
                onPrevious={previous}
                onCreateProduct={handleCreateProduct}
                isEdit={isEdit}
                isKit
              />
            ),
          },
        ]}
      />
    </Main>
  );
}
