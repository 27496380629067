import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonAdd = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;

  background: #fff;
  border: 2px solid #85b074;
  color: #85b074;

  padding-left: 0.5px;
`;

export const ButtonRemove = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;

  background: #fff;
  border: 2px solid #99686f;
  color: #99686f;

  padding-left: 0.5px;
`;

export const Helper = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #636363;

  margin-bottom: 15px;
`;

export const HelperLink = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #85b074;
  text-decoration: underline;
`;

export const SelectedProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const Description = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #636363;

  display: block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const PriceContainer = styled.div`
  flex: 3;

  display: flex;
  flex-direction: column;

  margin-right: 12px;
`;

export const AmountContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  width: ${({ width }) => `${width}px`};
  height: 32px;
  padding: 5px;
  box-sizing: border-box;

  border: 1px solid #8a8a8a;
  border-radius: 4px;

  background: #fff;

  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Label = styled.label`
  text-align: center;

  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 12px;

  line-height: 16px;
  letter-spacing: 0.2px;

  color: #85b074;
`;
