import { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { BreadcrumbStepper, useStepper } from 'sonoma-design-system';

import { getUser, getUserRoles } from '../../auth/auth';

import VinhoForm from '../../components/VinhoForm';
import InformacoesForm from '../../components/InformacoesForm';
import FreteForm from '../../components/FreteForm';
import ImagensForm from '../../components/ImagensForm';

import { clearAll as clearWine } from '../../store/vinhoNew';
import { clearAll as clearInfo } from '../../store/informacoesForm';
import { clearAll as clearFreight } from '../../store/freteForm';
import { fetchProduct, clearAll as clearWineEdit } from '../../store/vinhoEdit';
import {
  fetchProduct as fetchInfoEdit,
  clearAll as clearInfoEdit,
} from '../../store/informacoesEdit';
import {
  fetchProduct as fetchFreteEdit,
  clearAll as clearFreightEdit,
} from '../../store/freteEdit';
import {
  fetchProduct as fetchImagensForm,
  clearAll as clearImagensForm,
} from '../../store/imagensForm';
import store from '../../store';

import { create as createProduct } from '../../services/ProductService';
import { create as sellerCreateProduct } from '../../services/SellerProductService';

import {
  clearBlankKeys,
  filterKeys,
  removeKeys,
  clearBlankKeysFromNested,
} from '../../utils/objects';

import { Main, Title } from './styles';
import { clearDB } from '../../config/db';

export default function EditVinho() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { pro_in_codigo } = useParams();

  const { selected, next, previous } = useStepper();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch([
      fetchProduct(pro_in_codigo),
      fetchInfoEdit(pro_in_codigo),
      fetchFreteEdit(pro_in_codigo),
      fetchImagensForm(pro_in_codigo),
    ]);

    return () => {
      dispatch([
        clearWine(),
        clearInfo(),
        clearFreight(),
        clearWineEdit(),
        clearInfoEdit(),
        clearFreightEdit(),
        clearImagensForm(),
      ]);
      clearDB();
    };
  }, [dispatch, pro_in_codigo]);

  const titles = useMemo(
    () => ['VINHOS', 'INFORMAÇÕES', 'FRETE E VENDA', 'IMAGENS E VÍDEOS'],
    []
  );

  const scoreKeys = useMemo(
    () => [
      'pon_in_ws',
      'pon_in_tp',
      'pon_in_wes',
      'pon_in_bh',
      'pon_in_we',
      'pon_in_gp',
      'pon_in_rp',
      'pon_in_st',
      'pon_in_gd',
      'pon_in_js',
      'pon_in_jr',
      'pon_in_ag',
      'pon_in_ds',
      'pon_in_d',
      'pon_in_ta',
      'pon_in_pm',
      'pon_in_uw',
      'pon_in_ak',
      'pon_in_lm',
      'pon_in_ad',
      'pon_in_jr2',
      'pon_in_jd',
      'pon_in_s',
      'pon_in_v',
      'pon_in_gg',
      'pon_in_bh2',
      'pon_in_iwr',
      'pon_in_ce',
      'pon_in_mf',
    ],
    []
  );

  const getPontuacao = useCallback(
    (data) => clearBlankKeys(filterKeys(data, scoreKeys)),
    [scoreKeys]
  );

  const getMainImagePath = useCallback(
    (images) => images.find((img) => img.main)?.path,
    []
  );

  const getNewImageFiles = useCallback(
    (images) => images.filter((i) => !i.pim_st_imagesrc),
    []
  );

  const getPreviouslySavedURLs = useCallback(
    (images) =>
      images.filter((i) => i.pim_st_imagesrc).map((i) => i.pim_st_imagesrc),
    []
  );

  const handleCreateProduct = useCallback(
    async (images, produtoId) => {
      setLoading(true);

      const roles = getUserRoles();
      const user = getUser();
      const isSeller = roles.find((e) => e === 'SELLER');

      try {
        const { product } = store.getState();
        const productWithouBlank = clearBlankKeysFromNested(product.edit);

        const {
          vinho: { form: wineData },
          frete: { form: freightData },
          informacoes: { form: infoData },
          imagens: { form: imagens },
        } = productWithouBlank;

        const pontuacao = getPontuacao(wineData);

        const wine = removeKeys(wineData, scoreKeys);

        const {
          harmonizacoes,
          tipos,
          uvas,
          selos,

          pro_st_sku,
          pai_in_codigo,
          pai_name_to_create, // País name in case of create new one
          reg_in_codigo,
          reg_name_to_create, // Regiao name in case of create new one
          pro_st_conteudo,

          ...data
        } = wine;

        const {
          produtos_suplentes,
          safra,
          pro_lt_sobre,
          pro_lt_historia,
          slr_in_codigo,
          ...dataInfo
        } = infoData;

        const similares = Object.keys(produtos_suplentes).map((key) => ({
          pro_in_codigo: produtos_suplentes[key].child_pro_in_codigo,
          pro_st_sku: produtos_suplentes[key].children.pro_st_sku,
          pro_st_descricao: produtos_suplentes[key].children.pro_st_descricao,
        }));

        // main image path
        const main = getMainImagePath(images);

        // previous saved firebase files
        const previousURLs = getPreviouslySavedURLs(images);

        // new image files
        const newFiles = getNewImageFiles(images);

        // TODO - Refatorar
        if (slr_in_codigo || isSeller) {
          await sellerCreateProduct(
            clearBlankKeysFromNested({
              ...freightData,
              ...dataInfo,

              pro_lt_sobre: pro_lt_sobre?.replaceAll('<p></p>', '</br>'),
              pro_lt_historia: pro_lt_historia?.replaceAll('<p></p>', '</br>'),

              product_type: 'vinho',

              similares,

              // in case of edit on DBS we need to send pro_in_codigo
              pro_in_codigo: produtoId || null,

              pro_st_sku,
              pai_in_codigo,
              pai_name_to_create,
              reg_in_codigo,
              reg_name_to_create,
              pro_st_conteudo: pro_st_conteudo || '',
              pro_st_descricao: infoData.pro_st_descricao,
              pro_st_nomesite: infoData.pro_st_titulopage,
              pro_st_palavraschave: infoData.pro_st_tags,
              pro_st_video: imagens?.pro_st_video,

              updatedBy: user.usu_in_codigo,

              pro_de_take_produto: freightData.pro_de_take_produto || '',

              tipos,
              harmonizacoes,
              selos,
              vinho: {
                ...data,
                vin_in_safra: safra || '',
                vin_de_temperatura: data.vin_de_temperatura || '',
                vin_de_teoralcoolico: data.vin_de_teoralcoolico || '',
                uvas,
                pontuacao,
                vin_in_quantidadecaixa: Number.isNaN(
                  wine.vin_in_quantidadecaixa
                )
                  ? 0
                  : wine.vin_in_quantidadecaixa,
                vin_in_tempodescanso: Number.isNaN(data.vin_in_tempodescanso)
                  ? 0
                  : wine.vin_in_tempodescanso,
                vin_in_tempobarrica: Number.isNaN(data.vin_in_tempobarrica)
                  ? 0
                  : wine.vin_in_tempobarrica,
              },

              main,
              previousURLs,
            }),
            newFiles,
            slr_in_codigo
          );
        } else {
          await createProduct(
            clearBlankKeysFromNested({
              ...freightData,
              ...dataInfo,

              pro_lt_sobre: pro_lt_sobre?.replaceAll('<p></p>', '</br>'),
              pro_lt_historia: pro_lt_historia?.replaceAll('<p></p>', '</br>'),

              product_type: 'vinho',

              similares,

              // TODO: REMOVER QUANDO MLN VOLTAR
              pro_in_codigo: produtoId || null,
              pro_st_sku,
              pai_in_codigo,
              pai_name_to_create,
              reg_in_codigo,
              reg_name_to_create,
              pro_st_conteudo: pro_st_conteudo || '',
              pro_st_descricao: infoData.pro_st_descricao,
              pro_st_nomesite: infoData.pro_st_titulopage,
              pro_st_palavraschave: infoData.pro_st_tags,
              pro_st_video: imagens?.pro_st_video,

              updatedBy: user.usu_in_codigo,

              pro_de_take_produto: freightData.pro_de_take_produto || '',

              tipos,
              harmonizacoes,
              selos,
              vinho: {
                ...data,
                vin_in_safra: safra || '',
                vin_de_temperatura: data.vin_de_temperatura || '',
                vin_de_teoralcoolico: data.vin_de_teoralcoolico || '',
                uvas,
                pontuacao,
                vin_in_quantidadecaixa: Number.isNaN(
                  wine.vin_in_quantidadecaixa
                )
                  ? 0
                  : wine.vin_in_quantidadecaixa,
                vin_in_tempodescanso: Number.isNaN(wine.vin_in_tempodescanso)
                  ? 0
                  : wine.vin_in_tempodescanso,
                vin_in_tempobarrica: Number.isNaN(wine.vin_in_tempobarrica)
                  ? 0
                  : wine.vin_in_tempobarrica,
              },

              main,
              previousURLs,
            }),
            newFiles
          );
        }

        history.push('/produtos');
      } catch (err) {
        // handle error
        setLoading(false);
      }
    },
    [
      getPontuacao,
      getMainImagePath,
      getPreviouslySavedURLs,
      getNewImageFiles,
      history,
      scoreKeys,
    ]
  );

  return (
    <Main>
      <Title>Produtos e SKUs | {titles[selected]}</Title>

      <BreadcrumbStepper
        selected={selected}
        steps={[
          {
            title: 'Especificações',
            component: () => <VinhoForm onNext={next} isEdit />,
          },
          {
            title: 'Informações',
            component: () => (
              <InformacoesForm
                onNext={next}
                onPrevious={previous}
                isEdit
                wine
              />
            ),
          },
          {
            title: 'Frete e Venda',
            component: () => (
              <FreteForm onNext={next} onPrevious={previous} isEdit />
            ),
          },
          {
            title: 'Imagens e Vídeos',
            component: () => (
              <ImagensForm
                onPrevious={previous}
                onCreateProduct={handleCreateProduct}
                loading={isLoading}
                isEdit
              />
            ),
          },
        ]}
      />
    </Main>
  );
}
